<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0">
            <i class="fa fa-percent"></i> {{ $t("supplierFilter") }}
          </h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group mb-3">
            <label class="custom-label">Tip Seç</label>
            <v-select
              label="name"
              v-bind:value="form.type"
              v-bind:options="supplier.filter_types"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              class="setting-select"
              v-on:input="onFilterType"
            >
            </v-select>
          </div>
        </div>
      </div>
    </WeCard>
    <WeCard class="mt-3" v-if="step > 0">
      <WeCard class="alert-primary" v-if="step == 1">
        <span>
          <i class="fas fa-spinner fa-spin"></i> Veriler çekiliyor lütfen
          bekleyiniz...
        </span>
      </WeCard>
      <!-- Brand -->
      <div class="d-block mb-3" v-if="step == 2">
        <WeSearchInput
          class="mb-0 product-select"
          name="brand"
          :label="selectedFilterType.name + ' Ara'"
          v-bind:clearable="false"
          v-bind:custom-param="customParam"
          v-bind:filteredData="filteredData"
          placeholder="Ara"
          v-on:on-select="onSelect"
          ref="searchInput"
        />
        <div class="col-12 mt-5 w-table">
          <WeTable
            v-bind:index="false"
            v-bind:data="computedData"
            v-bind:columns="columns"
            v-bind:actions="actions"
            v-bind:limitable="false"
            v-bind:pageDetails="false"
            v-bind:paginatable="computedData.length > itemsPerPage"
            v-bind:itemsPerPage="itemsPerPage"
            v-bind:selectable="false"
            v-bind:row-class="'font-14'"
            v-on:on-action="onAction"
            v-bind:responsive="true"
          >
          </WeTable>
        </div>
      </div>
      <!-- Brand -->
    </WeCard>

    <WeLoading v-if="submit" />
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      step: 0,
      selectedFilterType: null,
      itemsPerPage: 16,
      form: {
        type: null,
      },
      actions: [
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger border-0 ml-2",
          action: "remove",
        },
      ],
      columns: [{ name: "name", th: "Marka Adı", type: "string" }],
      filteredData: [],
      customParam: {
        type_id: null,
      },
      brandList: [],
      filterPaginate: {
        brand: {
          item_count: 1,
          current_page: 1,
          total_page: 1,
          total_items: 1,
        },
      },
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("supplier", [
      "getBrandTypeList",
      "getSupplierFilter",
      "saveFilters",
    ]),
    ...mapActions("brand", ["getList"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.selectedFilterType) {
        return;
      }
      this.submit = true;
      this.updateOrCreate();
    },
    onAction(payload) {
      helper.addOrRemove(this.filteredData, payload.row);
      this.$refs.searchInput.selectData(this.filteredData);
    },
    sendSwalError(message) {
      this.$swal({
        title: "Hata",
        text: message,
        icon: "error",
      });
    },
    updateOrCreate() {
      this.saveFilters({
        type: this.selectedFilterType,
        filter_data: this.filteredData,
        onSuccess: (result) => {
          if (result.data.status && result.data.status == "success") {
            this.$swal({
              title: "Başarılı",
              text: "Kaydetme işlemi başarılı",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } else {
            this.sendSwalError("Kaydetme işlemi başarısız oldu!");
          }
        },
        onFinish: () => {},
      });
    },
    onSelect(payload) {
      if (!this.filteredData.find((A) => A.id == payload.id)) {
        this.filteredData.push(payload);
      }
      this.$refs.searchInput.selectData(this.filteredData);
    },
    onFilterType(payload) {
      this.form.type = payload;
      this.step = 1;
      var type = this.supplier.filter_types.find((A) => A.id == payload);
      this.selectedFilterType = type;
      this.getSupplierFilter({
        typeName: type.uniqKey ?? type.key,
        onSuccess: (result) => {
          switch (type.key) {
            case "brandType":
              this.customParam.type_id = type.uniqKey.split("_")[1];
              this.step = 2;
              this.filteredData = result.data;
              break;
          }
        },
      });
    },
  },
  computed: {
    ...mapState(["supplier", "shared", "session"]),
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
    computedData: {
      get() {
        return this.filteredData;
      },
      set(value) {
        this.filteredData = value;
      },
    },
  },
  async mounted() {
    this.supplier.filter_types = [];
    this.getBrandTypeList({
      onSuccess: (res) => {
        res.data.items.forEach((item) => {
          this.supplier.filter_types.push({
            id: item.id,
            name: item.name,
            key: "brandType",
            uniqKey: "brandType_" + item.id,
          });
        });
        this.ready = true;
      },
    });
  },
};
</script>
<style>
.pagination li:hover {
  cursor: pointer;
}
.pad-10-10 {
  padding: 10px 10px;
}
.margin-0 {
  margin-right: 0px;
  margin-left: 0px;
}
.vs__dropdown-menu {
  overflow: scroll !important;
}
.w-table .row {
  flex-direction: row-reverse !important;
}
</style>